<template>
  <VApp data-confirm-town>
    <div
      class="b-container confirm-town"
      :class="{
        'confirm-town_mobile': isMobile,
        'confirm-town_desktop': !isMobile,
      }"
    >
      <VBanner
        v-model="isVisible"
        :width="isMobile ? 'auto' : 280"
        :class="{ 'mr-4': !isMobile }"
        class="confirm-town__banner"
        color="bg-gray-0"
        elevation="8"
        rounded
      >
        <div class="ui-text ui-text_body-2 ui-kit-color-text mb-2">
          Ваш город — {{ townName }}?
        </div>
        <div class="d-flex justify-content-end">
          <VBtn
            class="button-reset mr-2"
            color="text"
            text
            small
            @click="handleClickChange"
          >
            Изменить
          </VBtn>
          <VBtn
            class="button-reset"
            color="primary"
            small
            depressed
            @click="handleClickConfirm"
          >
            Верно
          </VBtn>
        </div>
      </VBanner>
    </div>
  </VApp>
</template>

<script>
import Cookie from 'js-cookie'
import { dispatchCustomEventGlobally } from 'utils'
import { COOKIE_KEY } from 'components/common/ConfirmTown/constants'
import { EVENTS as CHOOSE_TOWN_EVENTS } from 'www/library.blocks/common/b-choose-town-btn/constants'

export default {
  name: 'ConfirmTown',
  props: {
    townName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isVisible: false,
    isMobile: window.MOBILE_VERSION,
  }),
  mounted() {
    // Нужно, чтобы работала анимация появления
    setTimeout(() => {
      this.isVisible = true
    })
  },
  methods: {
    setConfirmed() {
      Cookie.set(COOKIE_KEY, 'true', {
        expires: 365,
      })
    },
    handleClickChange() {
      if (this.isMobile) {
        window.location.assign('/town/')
      } else {
        dispatchCustomEventGlobally(CHOOSE_TOWN_EVENTS.switchTown)
      }

      this.isVisible = false
      this.setConfirmed()
    },
    handleClickConfirm() {
      this.isVisible = false
      this.setConfirmed()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.confirm-town {
  position: fixed;
  z-index: 100;
  padding: 0;

  &_desktop {
    display: flex;
    justify-content: flex-end;
    bottom: 28px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
  }

  &_mobile {
    bottom: 16px;
    left: 20px;
    right: 20px;
  }

  &__banner {
    pointer-events: initial;
  }
}
</style>

<style lang="scss">
@import '~www/themes/doctors/common/mixins';

#app[data-confirm-town] {
  @include v-app-reset;

  .v-banner__wrapper {
    padding: 12px 16px;
  }

  .v-banner__content {
    padding-right: 0;
  }
}
</style>
